import React, { useState } from "react";
import { Dialog } from "../dialog/Dialog";
import toast from "react-hot-toast";
import SVGS from "../../assets/svg";

interface VerifyButtonDialogIProps {
  show: boolean;
  onHide: () => void;
  id: any;
  submitAction: (id: any) => void;
}

const VerifyButtonDialog = ({
  show,
  onHide,
  id,
  submitAction,
}: VerifyButtonDialogIProps) => {
  return (
    <>
      {show && (
        <Dialog
          title={""}
          show={show}
          onHide={onHide}
          showCloseButton={true}
          className="!bg-[#FD591B]"
          size="lg"
        >
          <img src={SVGS.SubmitLogo} className="mx-auto" />
          <div className="w-full text-center text-white text-[22px] font-bold font-['Montserrat'] capitalize">
            Are you sure you have completed the task
          </div>

          <div className="mt-8">
            <div
              className="flex justify-center items-center p-4 cursor-pointer bg-black text-white rounded-xl uppercase"
              onClick={() => {
                submitAction(id);
                onHide();
              }}
            >
              <label className="ml-2 font-montserrat  font-bold text-[16px] leading-5 cursor-pointer">
                Confirm
              </label>
            </div>
            <div
              className="flex justify-center items-center p-4 cursor-pointer bg-black text-white rounded-xl uppercase mt-2"
              onClick={() => onHide()}
            >
              <label className="ml-2 font-montserrat  font-bold text-[16px] leading-5 cursor-pointer">
                Cancel
              </label>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default VerifyButtonDialog;
