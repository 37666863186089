import React, { useRef, useEffect, useState } from "react";
import SVGS from "../../assets/svg";
import { IoMdDoneAll } from "react-icons/io";
import { RiProgress7Line } from "react-icons/ri";
import { MdOutlinePending } from "react-icons/md";
import VerifyButtonDialog from "../submit-button/VerifyButtonDialog";

interface CollapsibleIProps {
  id: string;
  isExpanded: boolean;
  handleOpenCloseItinerary: any;
  title: string;
  description: string;
  rewards: string;
  task: string;
  icon: any;
  status: string;
  currentLocationActivityId: string;
  moveItineraryToInReview: any;
  password: string;
  activityLink: string;
  isStatic: boolean;
  verifyTask: any;
  isCompleted: any;
  data: any;
  checkIfComplete: any;
}

const STATIC_TASK_DATA = [
  {
    id: "99",
    getDescription: () => {
      return (
        <div className="">
          <label>
            The Ordinals Passport Phygital (Physical and Digital Experience)
            will help guide you through a curated and immersive journey through
            NFC | Ordinals Lisbon. For each item listed below, you will need to:
          </label>
          <ul className="list-decimal ml-4 mt-2">
            <li>
              Go to physical Activation locations that will be published on{" "}
              <a
                href="https://x.com/ordinalpassport"
                style={{ color: "blue" }}
                target="_blank"
              >
                @ordinalpassport
              </a>{" "}
              discord
            </li>
            <li>Scan QR code at Crypto Immigration checkpoints</li>
            <li>Take pics, videos and complete 1-3 social share tasks</li>
            <li>Submit your itinerary for review</li>
          </ul>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return (
        <div>
          <label>
            We have exciting rewards including invites to a FCFS Yacht party for
            those who complete the 26 activations. Ordinals Passport is a
            community project that has brought together rewards from various
            communities ranging from $1K to nearly $20K depending on your
            specific quest and winnings.
          </label>
        </div>
      );
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-decimal ml-4 mt-2">
            <li>
              Complete your first itinerary activation by going to the Xverse
              booth, scan the Crypto Immigration checkpoint QR, and follow the
              instructions
            </li>
            <li>
              Complete this same process for each activation, see you on the
              Ordinal Passport, Ferrum Yacht party, Pizza and Wine party and
              more.{" "}
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "1",
    getDescription: () => {
      return (
        <div>
          <label>
            Xverse is the leading Bitcoin wallet for everyone, providing a
            user-friendly gateway to the growing Bitcoin Web3 ecosystem. Engage
            to earn a Xverse stamp.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: (recordActivity: any) => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              {/* <label
                onClick={() => {
                  recordActivity(
                    "1",
                    "Download : xVerse Wallet on mobile",
                    "https://www.xverse.app/download"
                  );
                }}
                style={{ color: "blue" }}
                className="cursor-pointer"
              >
                Download :{" "}
              </label>
              <label>Xverse Wallet on mobile</label> */}
              <a
                target="_blank"
                style={{ color: "blue" }}
                href="https://www.xverse.app/download"
              >
                Download :{" "}
              </a>
              xVerse Wallet on mobile
            </li>
            <li>
              Follow{" "}
              <a
                href="https://x.com/XverseApp"
                style={{ color: "blue" }}
                target="_blank"
              >
                @XverseApp
              </a>{" "}
              on X
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/intent/post?text=The%20ultimate%20Bitcoin%20wallet%20for%20all%2C%20offering%20a%20sleek%20and%20intuitive%20gateway%20to%20the%20expanding%20Bitcoin%20Web3%20universe.%20%40xverseapp"
              >
                Click Here To Tweet This :{" "}
              </a>
              :&nbsp;The ultimate Bitcoin wallet for all, offering a sleek and
              intuitive gateway to the expanding Bitcoin Web3 universe.
              @XverseApp
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "2",
    getDescription: () => {
      return (
        <div>
          <label>
            1,500 Bitcoin Ordinal Ninjas on Pizza Sats by{" "}
            <a
              target="_blank"
              style={{ color: "blue" }}
              href="https://x.com/ninjalerts"
            >
              @ninjalerts
            </a>
            . View any Ninja in full size to reveal the secret menu. Featured in
            Sotheby's.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ninjalerts"
              >
                @ninjalerts
              </a>{" "}
              on X
            </li>
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/TO"
              >
                @TO
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/ninjalerts"
              >
                https://discord.gg/ninjalerts
              </a>
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/intent/post?text=Pizza%20and%20Ninjas.%20What%27s%20cooler%21%3F%20This%20is%20one%20of%20the%20most%20vibrant%20communities%20in%20the%20entire%20BTCFi%20ecosystem.%20Big%20thanks%20to%20Pizza%20Ninjas%20for%20making%20%40OrdinalsLisbon%20a%20reality%21%20%40ninjalerts%20%40TO%20%40OGKen100"
              >
                Click Here To Tweet This :{" "}
              </a>
              :&nbsp;Pizza and Ninjas. What's cooler!? This is one of the most
              vibrant communities in the entire BTCFi ecosystem. Big thanks to
              Pizza Ninjas for making @OrdinalsLisbon a reality! @ninjalerts @TO
              @OGKen100
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "3",
    getDescription: () => {
      return (
        <div>
          <label>
            Leo Caillard is a contemporary artist internationally recognized for
            the quality of his marble sculptures as well as for his digital
            creations at the cutting edge of new technologies.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/leocaillard"
              >
                @leocaillard
              </a>{" "}
              on X
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/intent/post?text=Leo%20Caillard%2C%20carved%20Runestones%20into%20history%20and%20inscribed%20into%20the%20largest%20block%20in%20Bitcoin%27s%20history.%20%40leocaillard%20"
              >
                Cick Here To Tweet
              </a>
              :&nbsp;Leo Caillard, carved Runestones into history and inscribed
              into the largest block in Bitcoin's history. @leocaillard
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "4",
    getDescription: () => {
      return (
        <div>
          <label>
            N0 0rdinary Kind (n0k): The latest collection (7,860 PFPs) from One
            Penny Piece delves into themes of digital privacy, autonomy, and
            resistance against the harms of centralized finance. Fiat has failed
            us. Dollars inflate, banks break, but Bitcoin is immutable
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return <div></div>;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/seizeCTRL"
              >
                @seizeCTRL
              </a>{" "}
              on X
            </li>
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/thesovereignbtc"
              >
                @thesovereignbtc
              </a>{" "}
              on X
            </li>
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/onepennypiece"
              >
                @onepennypiece
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="http://discord.gg/seizectrl"
              >
                http://discord.gg/seizectrl
              </a>
            </li>
            <li>
              <a
                target="_blank"
                style={{ color: "blue" }}
                href="https://x.com/intent/post?text=Practice%20safe%20BTC%20or%20get%20buried%20with%20fiat%20%23FiatFuneral%20%40SeizeCTRL%20%0A%20%0Ax.com%2FSeizeCTRL%2Fstatus%2F1795236927131771115"
              >
                Click Here To Tweet This :{" "}
              </a>
              :&nbsp;Practice safe BTC or get buried with fiat #FiatFuneral
              @SeizeCTRL x.com/SeizeCTRL/status/1795236927131771115
            </li>
            <li>
              Like and Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/SeizeCTRL/status/1795236927131771115"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "5",
    getDescription: () => {
      return (
        <div>
          <label>
            John is our hero, bringing together all the amazing creators,
            artists, and builders together here in Lisbon at NFCsummit |
            Ordinals Lisbon. If you are enjoying your experience, give him a
            shoutout.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/NFCsummit"
              >
                @NFCsummit
              </a>{" "}
              and{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/johnkarp"
              >
                @johnkarp
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.com/invite/W7ne8bmQVY"
              >
                https://discord.com/invite/W7ne8bmQVY
              </a>
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/intent/post?text=This%3A%20%E2%80%9CTime%20to%20cook%21%E2%80%9D%20-%20%40johnkarp"
              >
                Click Here to Tweet This :{" "}
              </a>
              “Time to cook!” -&nbsp;@johnkarp
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "6",
    getDescription: () => {
      return (
        <div>
          <label>
            Ferrum Network is pioneering interoperability 2.0. In Latin, Ferrum
            means Iron. Iron bonds with the most amount of elements with the
            least amount of friction. In the spirit of collaboration over
            competition, Ferrum is bringing together artists, curators, and
            projects alike through the Ordinals Lounge and the Ordinals
            Passport.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/FerrumNetwork"
              >
                @FerrumNetwork
              </a>{" "}
              ,{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ordinalpassport"
              >
                @ordinalpassport
              </a>{" "}
              ,{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/tahaabbasi"
              >
                @TahaAbbasi
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.com/invite/HEfKq57asd"
              >
                https://discord.com/invite/W7ne8bmQVY
              </a>
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/intent/post?text=Interoperability%20is%20at%20the%20core%20of%20%40FerrumNetwork%E2%80%99s%20mission%20and%20is%20on%20full%20display%20with%20their%20role%20at%20NFC%20with%20the%20Ordinals%20Passport"
              >
                Click Here to Tweet This :{" "}
              </a>
              Interoperability is at the core of @FerrumNetwork’s mission and is
              on full display with their role at NFC with the Ordinals Passport
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "7",
    getDescription: () => {
      return (
        <div>
          <label>
            An extremely talented Italian born singer songwriter based now in
            Nashville, Violetta Zironi is paving the way for musicians around
            the world. With streaming platforms and the general corruption in
            the music industry, Violetta is a voice and an evangelist for the
            power of Bitcoin and the autonomy it provides for artists.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ZironiVioletta"
              >
                @ZironiVioletta
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/violetta"
              >
                https://discord.gg/violetta
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "8",
    getDescription: () => {
      return (
        <div>
          <label>
            CryptoBags is a fun and engaging way to bridge the physical and
            digital divide. An IRL treasure hunt for the kid inside all of us.
            Find these beautifully crafted pieces loaded with all sorts of
            crypto treasures throughout the event center and surrounding areas.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/cryptobags_xyz"
              >
                @cryptobags_xyz
              </a>{" "}
              and
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/blokculture"
              >
                @blokculture
              </a>{" "}
              on X
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/intent/post?text=A%20paradox%20rests%2C%20where%20paper%20dies%2C%20%0AA%20seed%20of%20change%2C%20in%20disguise.%20%0ASeek%20the%20spark%2C%20beyond%20the%20bills%2C%20"
              >
                Click Here To Tweet This :{" "}
              </a>
              A paradox rests, where paper dies, A seed of change, in disguise.
              Seek the spark, beyond the bills,
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "9",
    getDescription: () => {
      return (
        <div>
          <label>
            Having helped in the creation process of Pizza Ninjas, Runestones,
            and more, Ordinals Bot makes it easy to inscribe ordinals on the
            Bitcoin network. Create your own collections through Ordinals Bot.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ordinalsbot"
              >
                @ordinalsbot
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.com/invite/9nBhVgCjct"
              >
                https://discord.com/invite/9nBhVgCjct
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "10",
    getDescription: () => {
      return (
        <div>
          <label>Emanuele Descanso</label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Dascanio_"
              >
                @Dascanio_
              </a>
              {" ,"}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/DamianoVault"
              >
                @DamianoVault
              </a>{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/_OrdinalStudio_"
              >
                @_OrdinalStudio_
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.com/invite/YnVGhvREyv"
              >
                https://discord.com/invite/YnVGhvREyv
              </a>
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Dascanio_/status/1706265340353663435?t=kv9s3ZqEFuVWGJSxtIwvIA&s=19"
              >
                Retweet This Tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "11",
    getDescription: () => {
      return (
        <div>
          <label>
            Stacks is an open-source blockchain network that leverages the
            security and capital of Bitcoin for smart contracts, decentralized
            finance (DeFi), non-fungible tokens (NFTs), and decentralized apps
            (DApps)
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Stacks"
              >
                @Stacks
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/5DJaBrf"
              >
                https://discord.gg/5DJaBrf
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "12",
    getDescription: () => {
      return (
        <div>
          <label>
            Magisat facilitates interactions with the Bitcoin network, providing
            a seamless user journey, allowing individuals to purchase, trade,
            and sell “rare satoshis”.{" "}
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Magisat_io"
              >
                @magisat_io
              </a>
              {" ,"}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://twitter.com/const_quary"
              >
                @const_quary
              </a>{" "}
              on X
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/const_quary/status/1779900200045187148?s=46&t=ns0AqTIWB9mtAn8P2isWEg"
              >
                Retweet This Tweet
              </a>
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/magisat"
              >
                https://discord.gg/magisat
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "13",
    getDescription: () => {
      return (
        <div>
          <label>
            An artist, a curator, and serial networker, Paiman was an
            instrumental piece in bringing together artists for the Ordinals
            Lounge and a co-creator of the Ordinals Passport.{" "}
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/studiopaiman"
              >
                @studiopaiman
              </a>{" "}
              on X
            </li>
            {/* <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.com/invite/HEfKq57asd"
              >
                https://discord.com/invite/HEfKq57asd
              </a>
            </li> */}
          </ul>
        </div>
      );
    },
  },
  {
    id: "13",
    getDescription: () => {
      return (
        <div>
          <label>
            Thank you to the NFC team for creating this special place for all of
            us Bitcoin enthusiasts to share the story of Bitcoin through
            Ordinals.{" "}
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/OrdinalsLisbon"
              >
                @OrdinalsLisbon
              </a>{" "}
              on X
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "14",
    getDescription: () => {
      return (
        <div>
          <label>
            Chainlink is one of the largest middleware providers in web3. Their
            contributions to the Ordinals Passport via Chainlink VRF has been
            invaluable.{" "}
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/chainlink"
              >
                @chainlink
              </a>
              {" ,"}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/SmartContract"
              >
                @SmartContract
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://chain.link/discord"
              >
                https://chain.link/discord
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "15",
    getDescription: () => {
      return (
        <div>
          <label>
            One of the leading global magazines. Their Mission: To give people
            the knowledge, resources, inspiration, and connections they need to
            achieve success. They are bringing this mission to web3 now through
            Forbes Web3 and their Legacy Pass initiative{" "}
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Forbes"
              >
                @Forbes
              </a>
              {" ,"}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ForbesWeb3"
              >
                @ForbesWeb3
              </a>{" "}
              on X
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "16",
    getDescription: () => {
      return (
        <div>
          <label>
            A real talent, Random Freaks is a visual artist from Porto,
            representing the beautiful country of Portugal right here at NFC.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/random_freaks"
              >
                @random_freaks
              </a>{" "}
              on X
            </li>
            {/* <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/Ykjvm7Xg"
              >
                https://discord.gg/Ykjvm7Xg
              </a>
            </li> */}
          </ul>
        </div>
      );
    },
  },
  {
    id: "17",
    getDescription: () => {
      return (
        <div>
          <label>
            Taproot Wizards makes bitcoin magical again with magic internet
            JPEGs inscribed forever on the Bitcoin blockchain. The Quantum Cats
            are on a mission to rescue OP_CAT from this state of purgatory and
            bring it back to the land of the living, so it can Make Bitcoin
            Magical.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/QuantumCatsXYZ"
              >
                @QuantumCatsXYZ
              </a>
              {" ,"}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/TaprootWizards"
              >
                @TaprootWizards
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.com/invite/taprootwizards"
              >
                https://discord.com/invite/taprootwizards
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "18",
    getDescription: () => {
      return (
        <div>
          <label>
            Magic Eden is the leading community-centric NFT marketplace. Home to
            the next generation of creators. Discover the best and latest NFT
            collections through Magic Eden.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/MagicEden"
              >
                @MagicEden
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/magiceden"
              >
                https://discord.gg/magiceden
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "19",
    getDescription: () => {
      return (
        <div>
          <label>
            The first 100% on-chain pfp NFT collection created entirely in a
            single transaction.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/OnChainMonkey"
              >
                @OnChainMonkey
              </a>
              {" ,"}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/huuep"
              >
                @huuep
              </a>{" "}
              on X
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "20",
    getDescription: () => {
      return (
        <div>
          <label>
            Runestone, a pre-Runes NFT project, airdropped a free Runestone to
            wallets that had 3 or more digital items (inscriptions) on the
            Bitcoin blockchain before the Runestone project launched. $DOG is
            the the meme to rule them all on the Bitcoin network. To the MOON!
            LeonidasNFT is an Ordfluencer, Runesmaster, and host to The Ordinal
            Show
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://twitter.com/LeonidasNFT"
              >
                @LeonidasNFT
              </a>{" "}
              on X
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/intent/post?text=Initiating%20moon%20mission%20for%20%24DOG.%20"
              >
                Click Here To Tweet This :{" "}
              </a>
              :&nbsp;Initiating moon mission for $DOG.
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "21",
    getDescription: () => {
      return (
        <div>
          <label>I am loving it</label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/McDonalds/status/1485726032967704582"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "22",
    getDescription: () => {
      return (
        <div>
          <label>
            Chief FUD officer Elizabeth Warren leads the war party against
            crypto in the US Senate. She's routinely proven she knows as much
            about blockchain as she is native american, 1/1024th of what she
            claims.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://twitter.com/SenWarren"
              >
                @SenWarren
              </a>{" "}
              and{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ewarren"
              >
                @ewarren
              </a>{" "}
              on X
            </li>
            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/coinbureau/status/1794547011926315258"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "23",
    getDescription: () => {
      return (
        <div>
          <label>
            Chief FUD officer Elizabeth Warren leads the war party against
            crypto in the US Senate. She's routinely proven she knows as much
            about blockchain as she is native american, 1/1024th of what she
            claims.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/GaryGensler"
              >
                @GaryGensler
              </a>{" "}
              on X
            </li>
            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/GaryGensler/status/1623777842000539648?t=gc3dFApZ_F2HfcjgdFplog&s=19"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "24",
    getDescription: () => {
      return (
        <div>
          <label>
            Head strategist for the Inverse Cramer ETF, Jimmy's given us some of
            the hottest calls and crypto "breakdowns" (insert tear emoji) of the
            cycle.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/jimcramer"
              >
                @jimcramer
              </a>{" "}
              and{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/CramerTracker"
              >
                @CramerTracker
              </a>{" "}
              on X
            </li>
            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/jimcramer/status/1612403708759019520?lang=en"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "24",
    getDescription: () => {
      return (
        <div>
          <label>
            Head strategist for the Inverse Cramer ETF, Jimmy's given us some of
            the hottest calls and crypto "breakdowns" (insert tear emoji) of the
            cycle.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/jimcramer"
              >
                @jimcramer
              </a>{" "}
              and{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/CramerTracker"
              >
                @CramerTracker
              </a>{" "}
              on X
            </li>
            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/jimcramer/status/1612403708759019520?lang=en"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "25",
    getDescription: () => {
      return (
        <div>
          <label>We are all indeed SATOSHI</label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/intent/post?text=With+the+exception+of+%40Dr_CSWright...we+are+all+Satoshi%21+WAGMI"
              >
                Click Here To Tweet This{" "}
              </a>
              :&nbsp;With the exception of @Dr_CSWright...we are all Satoshi!
              WAGMI
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "26",
    getDescription: () => {
      return (
        <div>
          <label>
            Complete the form and tasks to get airdrops, rewards and more from
            activation partners{" "}
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <div>
            <ul className="list-disc ml-4 mt-2">
              <li>
                Follow{" "}
                <a
                  style={{ color: "blue" }}
                  target="_blank"
                  href="https://x.com/ordinalpassport"
                >
                  @ordinalpassport
                </a>{" "}
                on X
              </li>
              <li>
                <a
                  style={{ color: "blue" }}
                  target="_blank"
                  href="https://x.com/intent/post?text=Just%20completed%20my%20final%20activation%20in%20my%20genesis%20%23ordinalspassport%20at%20%40OrdinalsLisbon%20powered%20by%20%40FerrumNetwork%20tech%20in%20collaboration%20with%20%40SeizeCTRL%2C%20and%20%40Pizza_Ninjas"
                >
                  Click Here To Tweet This{" "}
                </a>
                :&nbsp;Just completed my final activation in my genesis
                #ordinalspassport at @OrdinalsLisbon powered by @FerrumNetwork
                tech in collaboration with @SeizeCTRL, and @Pizza_Ninjas
              </li>
              <li>Fill out the form to get airdrops and rewards</li>
            </ul>
          </div>
        </div>
      );
    },
  },
  {
    id: "27",
    getDescription: () => {
      return (
        <div>
          <label>
            Pizza and Ninjas. Whats cooler!? This is one of the most vibrant
            communities in the entire BTCFi ecosystem. Big thanks to Pizza
            Ninjas for making Ordinals Lisbon a reality!
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Pizza_Ninjas"
              >
                @Pizza_Ninjas
              </a>{" "}
              on X
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/ninjalerts"
              >
                https://discord.gg/ninjalerts
              </a>
            </li>

            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/TO/status/1814892503318811045"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "28",
    getDescription: () => {
      return (
        <div>
          <label>Pay respects and disrupt the space</label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/seizeCTRL"
              >
                @seizeCTRL
              </a>{" "}
              on X
            </li>

            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="http://discord.gg/seizectrl"
              >
                http://discord.gg/seizectrl
              </a>
            </li>

            <li>
              Like and Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/SeizeCTRL/status/1815404923020034189"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "29",
    getDescription: () => {
      return (
        <div>
          <label>
            Interoperability is at the core of Ferrum's mission and is on full
            display with their role at NFC with the Ordinals Passport.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/FerrumNetwork"
              >
                @FerrumNetwork
              </a>{" "}
              on X
            </li>
            <li>
              Join Telegram:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="http://t.me/ferrum_network"
              >
                http://t.me/ferrum_network
              </a>
            </li>
            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Ferrum_Intern/status/1815753102508585462"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "30",
    getDescription: () => {
      return (
        <div>
          <label>
            Italian singer-songwriter, one of the first artists to enter web3.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ZironiVioletta"
              >
                @ZironiVioletta
              </a>{" "}
              on X
            </li>

            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/violetta"
              >
                https://discord.gg/violetta
              </a>
            </li>
            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ZironiVioletta/status/1763347328902000824"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "31",
    getDescription: () => {
      return (
        <div>
          <label>
            Harnessing blockchain magic, unleashing revolutionary tech for
            artistic freedom!
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Dascanio_"
              >
                @Dascanio_
              </a>
              on X
            </li>

            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Dascanio_/status/1706265340353663435?t=ONSRLuY9aqWJv9GqxgKSgw&s=19"
              >
                Retweet This Tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "32",
    getDescription: () => {
      return (
        <div>
          <label>
            Incredibly engaging IRL activation project that allows participants
            to get in touch with their inner child with a treasure hunt!
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/cryptobags_xyz"
              >
                @cryptobags_xyz
              </a>{" "}
              on X
            </li>
            <li>
              Retweet&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/blokculture/status/1760610619886071826?s=46&t=xZGKcmC_jMnLKtBZj0ipNA"
              >
                this tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "33",
    getDescription: () => {
      return (
        <div>
          <label>Everyone's favorite tool for rare sat hunting.</label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Magisat_io"
              >
                @magisat_io
              </a>{" "}
              on X
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/const_quary/status/1779900200045187148?s=46&t=ns0AqTIWB9mtAn8P2isWEg"
              >
                Retweet This Tweet
              </a>
            </li>
            <li>
              Join Discord:&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://discord.gg/magisat"
              >
                https://discord.gg/magisat
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "34",
    getDescription: () => {
      return (
        <div>
          <label>
            Leo Caillard, carved Runestones into history and inscribed into the
            largest block in Bitcoin's history.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/leocaillard"
              >
                @leocaillard
              </a>{" "}
              on X
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/leocaillard/status/1765052114533826770"
              >
                Retweet This Tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "35",
    getDescription: () => {
      return (
        <div>
          <label>
            Runes, Ordinals, BRC20, Rare Sats and any inscription simplified and
            accessible on EVM chains via MetaMask.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/0xMultiSwap"
              >
                @MultiSwap
              </a>{" "}
              on X
            </li>

            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href=" http://t.me/OxMultiSwap"
              >
                @0xMultiSwap
              </a>{" "}
              on Telegram
            </li>
            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/Ferrum_Intern/status/1815754569621655921"
              >
                Retweet This Tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "36",
    getDescription: () => {
      return (
        <div>
          <label>
            The Hell Money podcast is about Bitcoin, the collapsing fiat
            monetary system, and raising chickens in your backyard.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/hellmoneypod"
              >
                @hellmoneypod
              </a>{" "}
              on X
            </li>

            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/hellmoneypod/status/1813619681292333338"
              >
                Retweet This Tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
  {
    id: "37",
    getDescription: () => {
      return (
        <div>
          <label>
            The orange one, God Emperor Trump recently gave us his blessing
            guaranteeing our bags fly higher than his toupee in a stiff breeze.
          </label>
        </div>
      );
    },
    getContent: () => {
      return <div></div>;
    },
    getRewards: () => {
      return undefined;
    },
    getTasks: () => {
      return (
        <div>
          <ul className="list-disc ml-4 mt-2">
            <li>
              Follow&nbsp;
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/ordinalpassport"
              >
                @ordinalpassport
              </a>{" "}
              on X
            </li>

            <li>
              <a
                style={{ color: "blue" }}
                target="_blank"
                href="https://x.com/FerrumNetwork/status/1815829951771214100"
              >
                Retweet This Tweet
              </a>
            </li>
          </ul>
        </div>
      );
    },
  },
];

const Collapsible = ({
  id,
  title,
  description,
  rewards,
  task,
  isExpanded,
  handleOpenCloseItinerary,
  icon,
  status,
  currentLocationActivityId,
  moveItineraryToInReview,
  password,
  activityLink,
  isStatic,
  verifyTask,
  isCompleted,
  data,
  checkIfComplete,
}: CollapsibleIProps) => {
  const [amIExpanded, setAmIExpanded] = useState<boolean>(false);
  const [showVerifyDialog, setShowVerifyDialog] = useState<boolean>(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState<boolean>(false);
  const activity = useRef(null);

  useEffect(() => {
    if (data?.length) {
      console.log({ data, id });
      const task = checkIfTaskComplete(id);
      console.log({ task, id });
      // if (task) {
      //   setIsTaskCompleted(true);
      // }
      // const taskD = checkIfTaskComplete(id);
      // console.log({ taskD, id });
      // if (taskD) {
      //   setIsTaskCompleted(true);
      // }
    }
  }, [id, data]);

  useEffect(() => {
    if (currentLocationActivityId === id && !isStatic) {
      //@ts-ignore
      activity?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentLocationActivityId, id]);

  const createMarkup = (content: string) => {
    return { __html: content };
  };

  const checkIfTaskComplete = (id: any) => {
    const storedData = localStorage.getItem("verification");
    if (storedData) {
      const dataArray = JSON.parse(storedData);
      console.log({ dataArray, id });
      const isCompleted = dataArray.find(
        (item: any) => item.id === id && item.status === "completed"
      );
      console.log(isCompleted, id);
      return isCompleted ? true : false;
    }
  };

  const recordActivity = async (
    id: string,
    action: string,
    targetLink: string
  ) => {
    console.log(id, undefined, undefined, true, action);
    await moveItineraryToInReview(id, undefined, undefined, true, action);
    window.open(targetLink, "_blank");
  };

  const getTask = (toFind: String) => {
    const result = STATIC_TASK_DATA.find((st) => st.id === toFind);
    if (result) {
      return result;
    } else {
      return {
        id: "1",
        getDescription: () => {
          return "Coming later today!";
        },
        getContent: () => {
          return "Coming later today!";
        },
        getRewards: () => {
          return "Coming later today!";
        },
        getTasks: () => {
          return "Coming later today!";
        },
      };
    }
  };

  // console.log({ id, isCompleted });

  return (
    <>
      <div
        className="w-full max-w-md pl-2 pr-2 flex justify-between "
        id={id}
        ref={activity}
      >
        <div className="rounded-xl w-full bottom-border-v3 bg-[#f6af95] pt-4 pb-4 pl-2 pr-2">
          <div className="flex justify-between">
            <div className="flex items-center">
              <img
                src={
                  isStatic
                    ? "https://ordinals-passport-pfps.s3.eu-north-1.amazonaws.com/0.png"
                    : icon
                }
                alt="itinerary-1"
                style={{ maxWidth: "27px" }}
              />

              <label className="ml-4 font-montserrat !text-xs text-lg-bold-custom-v6">
                {title}
              </label>
            </div>

            <div
              className="bg-[#ec5a23] pr-4 pl-4 rounded-lg mr-2 font-pt-mono text-md-normal-custom-v7 flex items-center cursor-pointer"
              onClick={() => {
                if (isStatic) {
                  setAmIExpanded(!amIExpanded);
                } else {
                  handleOpenCloseItinerary(id);
                }
              }}
            >
              <label className="cursor-pointer text-xs mr-1">More Info</label>
              {isCompleted || isTaskCompleted ? (
                <IoMdDoneAll />
              ) : (
                <MdOutlinePending />
              )}
              <img
                className="ml-1"
                src={
                  isExpanded || currentLocationActivityId === id
                    ? SVGS.DropDownOpenIcon
                    : SVGS.DropDownCloseIcon
                }
                alt="drop-down-icon"
              />
            </div>
          </div>
          {((isStatic && amIExpanded) ||
            isExpanded ||
            currentLocationActivityId === id) && (
            <div>
              <div className="mt-4 ml-2 mr-2">
                <label className="font-montserrat text-lg-bold-custom-v6">
                  BRIEF OVERVIEW
                </label>
                <p className="font-montserrat text-md-normal-custom-v8">
                  {/* //@ts-ignore */}
                  {getTask(id)?.getDescription()}
                </p>
              </div>
              {isStatic || getTask(id)?.getRewards() ? (
                <div className="mt-4 ml-2 mr-2">
                  <label className="font-montserrat text-lg-bold-custom-v6">
                    REWARDS
                  </label>
                  <p className="font-montserrat text-md-normal-custom-v8">
                    {/* //@ts-ignore */}
                    {getTask(id)?.getRewards()}
                  </p>
                </div>
              ) : undefined}
              <div className="mt-4 ml-2 mr-2">
                <label className="font-montserrat text-lg-bold-custom-v6">
                  TASK
                </label>
                <p className="font-montserrat text-md-normal-custom-v8">
                  {/* //@ts-ignore */}
                  {getTask(id)?.getTasks(recordActivity)}
                </p>
              </div>

              {!isCompleted ? (
                <div className="flex justify-center items-center">
                  <label className="text-xs mt-4 pt-2 pb-2 pr-4 pl-4">
                    Click to verify the tasks
                  </label>
                  <button
                    className="bg-[#ec5a23] rounded-lg mt-4 pt-2 pb-2 pr-4 pl-4 text-white text-xs"
                    onClick={() => {
                      // verifyTask(id);
                      setShowVerifyDialog(true);
                    }}
                  >
                    Verify
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      {showVerifyDialog ? (
        <VerifyButtonDialog
          id={id}
          show={showVerifyDialog}
          onHide={() => setShowVerifyDialog(false)}
          submitAction={(id: any) => verifyTask(id)}
        />
      ) : null}
    </>
  );
};
export default Collapsible;
