//@ts-ignore
import axios from "axios";
import queryString from "query-string";
import { BASE_URL } from "../apis/ordinalsPassportAPI";

// const API_KEY = "U5Vojvw15M23tjdivjNCW1kyd";
// const API_SECRET = "9shvNnqN9c6l2wMZUNTS2Gy7lxrNM9jsTqEDLuCO3f5WwNUcbG";
// const BEARER_TOKEN =
//   "AAAAAAAAAAAAAAAAAAAAAK3%2FtwEAAAAAoF3FImlHKqHWE34Q%2B7sfm5%2BBhN0%3DYS2Ke1zB6YH8qWihvqIjHGPOeg0GgVUTXgjhtP0IOaWGecD8ax";
// const ACCESS_TOKEN = "1795043228221116417-fOXjVvtJsFmGL0Zo5HANKeFn0LzLvj";
// const ACCESS_TOKEN_SECRET = "NBVs1BSdV5LHHeZTeXG0wxNsC6BqHPPdF8jDjFkPW7WAp";
// const APP_ID = "28835757";
const APP_NAME = "1795044109549264897AbdulAh2001";
// const CLIENT_SECRET = "fwIj6fUHxN3hye6x6Bfk-iMGgudjEC3DmtsJj8rn42BY5AV-SI";
// const CLIENT_ID = "YlRvOHVMcUdNSUlaT01JN09yM1g6MTpjaQ";
// const REDIRECT_URI = "http://localhost:3000/twitter-callback";
const REDIRECT_URI = "https://ordinalspassport.com/twitter-callback";
const AUTHORIZATION_ENDPOINT = "https://twitter.com/i/oauth2/authorize";
const USET_TOKEN_ENDPOINT = "https://api.twitter.com/2/oauth2/token";
const USER_PROFILE_ENDPOINT = "https://api.twitter.com/2/users/me";

const APP_ID = "28836428";
const API_KEY = "HygRZz4MpH4ONOI2m0TVoadth";
const API_SECRET = "7cFmB07fTrpSk91I40G2jqidMIoNLzeMZ0uMFJGiYmJ77f87Ob";
const BEARER_TOKEN =
  "AAAAAAAAAAAAAAAAAAAAAEwCuAEAAAAAGpHXZs94TDVHtaxbX696GfRTpVw%3Dzd93NegoWI3xWd6j31ZU6nEzaoYhJPqUlzMn5sLsMrcpwsBnrI";
const CLIENT_ID = "UkdHUTV6LU1LVnhWZkltcEJObVA6MTpjaQ";
const CLIENT_SECRET = "vu43usnXDKL6i2N38pdxaLTK0aCVGMz8nzVjrvvqDutGiSemNy";

export const twitterOAuth = () => {
  return `${AUTHORIZATION_ENDPOINT}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=like.read%20tweet.read%20offline.access%20users.read%20follows.read%20follows.write&state=state&code_challenge=challenge&code_challenge_method=plain`;
};

// export const getTwitterUserOAuthToken = async (code: string) => {
//   const response = await axios.post(
//     `${BASE_URL}/passport/get-twitter-user-access-details`,
//     { code }
//   );
//   if (response?.data?.body?.userAccessData) {
//     storeTwiterAccessToken(response?.data?.body?.userAccessData.access_token);
//     storeTwiterRefreshToken(response?.data?.body?.userAccessData.refresh_token);
//     storeTwiterScopes(response?.data?.body?.userAccessData.scope);
//     return response.data.body.userAccessData;
//   } else {
//     return undefined;
//   }
// };

export const connectTwitter = async (code: string, discordId: string) => {
  const response = await axios.post(`${BASE_URL}/passport/connect-twitter`, {
    code,
    discordId,
  });
  if (response?.data?.body?.userProfile?.data) {
    return response.data.body.userProfile?.data;
  } else {
    return undefined;
  }
};

export const getUserTweets = async (
  accessToken: string,
  refreshToken: string,
  userId: string
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/passport/get-twitter-user-tweets/${userId}`,
      { accessToken, refreshToken }
    );
    if (response?.data?.body?.userTweets?.data) {
      return response.data.body.userTweets.data;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const getUserLikedTweets = async (
  accessToken: string,
  refreshToken: string,
  userId: string
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/passport/get-twitter-user-liked-tweets/${userId}`,
      { accessToken, refreshToken }
    );
    if (response?.data?.body?.userLikedTweets?.data) {
      return response.data.body.userLikedTweets.data;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};

export const storeTwiterCode = (token: string) => {
  localStorage.setItem("twitter_code", token);
};

export const getStoredTwiterCode = () => {
  return localStorage.getItem("twitter_code");
};

// export const storeTwiterAccessToken = (token: string) => {
//   localStorage.setItem("twitter_access_token", token);
// };

// export const getStoredTwiterAccessToken = () => {
//   return localStorage.getItem("twitter_access_token");
// };

// export const storeTwiterRefreshToken = (token: string) => {
//   localStorage.setItem("twitter_refresh_token", token);
// };

// export const getStoredTwiterRefreshToken = () => {
//   return localStorage.getItem("twitter_refresh_token");
// };

// export const storeTwiterScopes = (token: string) => {
//   localStorage.setItem("twitter_scopes", token);
// };

// export const getStoredTwiterScopes = () => {
//   return localStorage.getItem("twitter_scopes");
// };

export const removeTwiterTokens = () => {
  //   localStorage.removeItem("twitter_access_token");
  //   localStorage.removeItem("twitter_refresh_token");
  //   localStorage.removeItem("twitter_scopes");
  localStorage.removeItem("twitter_code");
};
