import React from  "react";
import SVGS from "../../assets/svg";
import lol from "../../assets/png/ordinals-passport.png"

const Header = () =>{
    return <div className="header h-36 w-full max-w-md">
        <div className="header-inner flex justify-center items-center ml-4 mr-4 mb-2">
            <div className="grid place-items-center">
                <img src={lol} alt="logo"/>
                {/* <img className="" src={SVGS.FerrumNetworkLogo} alt="logo"/> */}
            </div>
        </div>
    </div>
}

export default Header;