import React from "react";
import SVGS from "../../assets/svg";
interface TierInformationIProps {
  isDiscordConnected: boolean;
  totalActivations: number;
  completedActivations: number;
  getMyTier: any;
}
export const TierInformation = ({
  totalActivations,
  completedActivations,
  isDiscordConnected,
  getMyTier,
}: TierInformationIProps) => {
  const numberOfNextActivitiesNeeded = () => {
    if (completedActivations < 4) {
      return 4 - completedActivations;
    } else if (completedActivations < 8) {
      return 8 - completedActivations;
    } else if (completedActivations < 11) {
      return 11 - completedActivations;
    }
  };
  console.log({ totalActivations, completedActivations });
  return (
    <div className="p-2 w-full max-w-md">
      <div className="rounded-xl p-2 bg-[url('/src/assets/svg/passport-tier.svg')] bg-cover bg-no-repeat bg-center bg-[#E94000] bg-opacity-4">
        <div className="flex justify-between ">
          <div className="flex items-center rounded-xl p-2 font-montserrat text-lg-bold-custom-v3 bg-[#ed7b50]">
            <img className="mr-2" src={SVGS.TrophyIcon} alt="trophy-icon" />
            <label>
              TIER{" "}
              {Number(getMyTier()) + 1 === 3
                ? "SATOSHI"
                : Number(getMyTier()) + 1}
            </label>
          </div>
          <div className="p-2 font-montserrat text-lg-bold-custom-v4">
            <label>SEE ALL ACTIVATIONS</label>
          </div>
        </div>

        <div className="flex justify-between mt-4 ml-2">
          <div className=" w-[4.5rem] bg-[url('/src/assets/svg/clock.svg')] bg-cover bg-no-repeat bg-center flex justify-center items-center">
            <label className="text-lg-bold-custom-v5 font-montserrat">
              {completedActivations}
            </label>
          </div>
          {isDiscordConnected ? (
            <div className="w-full ml-4 font-montserrat text-md-normal-custom-v6 ">
              <label className="w-full flex justify-center font-pt-mono font-normal text-[20px] leading-6">
                ACTIVATIONS COMPLETED
              </label>
              <div className="w-full bg-white rounded-full h-1  flex items-center p-1 mt-1">
                <div
                  className="bg-[#E94000] h-1 rounded-full w-full"
                  style={{
                    width: `${
                      (completedActivations / totalActivations) * 100
                    }%`,
                  }}
                ></div>
              </div>
              {getMyTier() !== "SATOSHI" && (
                <div className="w-full flex justify-center mt-1">
                  <label className=" font-pt-mono font-normal text-[14px] leading-4">
                    {numberOfNextActivitiesNeeded()} ACTIVATIONS UNTIL
                  </label>
                  <label className="ml-2 font-pt-mono font-bold text-[14px] leading-4">
                    TIER{" "}
                    {Number(getMyTier()) + 1 === 3
                      ? "SATOSHI"
                      : Number(getMyTier()) + 1}
                  </label>
                </div>
              )}
            </div>
          ) : (
            <div className="w-full ml-4 font-montserrat text-md-normal-custom-v6">
              <label>
                Claim your passport to reach Tier 1 and unlock exclusive
                rewards!
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TierInformation;
