import React from "react";
import { Dialog } from "../dialog/Dialog";
import SVGS from "../../assets/svg";

interface ConnectDiscordDialogIProps {
  show: boolean;
  onHide: () => void;
  joinChannel: () => void;
}

const JoinDiscordChannelDialog = ({
  show,
  onHide,
  joinChannel,
}: ConnectDiscordDialogIProps) => {
  return (
    <>
      {show && (
        <Dialog
          title="JOIN ORDINALS DISCORD CHANNEL"
          show={show}
          onHide={onHide}
          showCloseButton={true}
          size="lg"
        >
          <div className="mt-8">
            <div>
              <label className="font-pt-mono text-[12] font-normal leading-3 text-white">
                {
                  "To perform this activity you first need to join Ordinals Passport discord channel, after joining the channel you need to scan the QR code again to perform the activation. this is a one time action"
                }
              </label>
            </div>
            <div
              className="rounded-xl bg-white mt-8 cursor-pointer"
              onClick={() => {
                joinChannel();
              }}
            >
              <div className="flex justify-center items-center p-4 cursor-pointer">
                <img
                  className="cursor-pointer"
                  src={SVGS.DiscordLogo}
                  alt="discord-logo"
                />
                <label className="ml-2 font-montserrat font-bold text-[16px] leading-5 cursor-pointer">
                  Join Channel
                </label>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default JoinDiscordChannelDialog;
