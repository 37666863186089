import React, { useEffect } from "react";
import { Dialog } from "../dialog/Dialog";
import SVGS from "../../assets/svg";
import { copyTextToClipboard } from "../../utils/helpers";
import toast from "react-hot-toast";

interface ConnectDiscordDialogIProps {
  show: boolean;
  onHide: () => void;
  password: string;
  activityLink: string;
}

const LeavingForActivityDialog = ({
  show,
  onHide,
  password,
  activityLink
}: ConnectDiscordDialogIProps) => {
  useEffect(() => {
    if (show) {
    }
  }, [password, show]);
  return (
    <>
      {show && (
        <Dialog
          title="LEAVING FOR ACTIVITY"
          show={show}
          onHide={onHide}
          showCloseButton={true}
          size="lg"
        >
          <div className="mt-8 flex justify-center">
            <div>
              <div className="flex justify-center items-center text-center">
                <label className="font-pt-mono text-[17px] font-normal leading-5 text-white">
                  {"To access this activity below is your password"}
                </label>
              </div>
              <div className="flex justify-center mt-4">
                <label className="font-pt-mono text-[30px] font-bold leading-9 text-white">
                  {password}
                </label>
                <img
                  className="ml-4 cursor-pointer"
                  src={SVGS.CopyIcon}
                  alt="copy-icon"
                  onClick={() => {
                    copyTextToClipboard(password);
                    toast.success("Copied");
                  }}
                />
              </div>
              <div
                className="rounded-xl bg-white mt-8 cursor-pointer"
                onClick={() => {
                    window.open(
                        activityLink,
                        "_blank",
                        "noopener,noreferrer"
                      );
                }}
              >
                <div className="flex justify-center items-center p-4 cursor-pointer">
                  <img
                    className="cursor-pointer"
                    src={SVGS.DiscordLogo}
                    alt="discord-logo"
                  />
                  <label className="ml-2 font-montserrat font-bold text-[16px] leading-5 cursor-pointer">
                    Start Activity
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default LeavingForActivityDialog;
