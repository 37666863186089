import React, { useState } from "react";
import { Dialog } from "../dialog/Dialog";
import toast from "react-hot-toast";
import SVGS from "../../assets/svg";

interface ConfirmationDialogIProps {
  show: boolean;
  onHide: () => void;
}

const ConfirmationDialog = ({ show, onHide }: ConfirmationDialogIProps) => {
  return (
    <>
      {show && (
        <Dialog
          title={""}
          show={show}
          onHide={onHide}
          showCloseButton={true}
          className="uppercase"
          size="lg"
        >
          <img src={SVGS.SunIcon} className="mx-auto" />
          <div className="w-[351px] text-center text-white text-[22px] font-bold font-['Montserrat'] capitalize">
            make sure you complete all activations to participate
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ConfirmationDialog;
