import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/shared/Header";
import SVGS from "../../assets/svg";
import UserInfromation from "../../components/profile/UserInfromation";
import Passport from "../../components/profile/Passport";
import TierInformation from "../../components/profile/TierInformation";
import Collapsible from "../../components/collapsible/Collapsible";
import UserDetails from "../../components/profile/UserDetails";
import Footer from "../../components/shared/Footer";
import ConnectDiscordDialog from "../../components/discord/ConnectDiscordDialog";
import PassportDetailsDialog from "../../components/passport-details/PassportDetailsDialog";
import toast, { Toaster } from "react-hot-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  checkUserIfUserIsAlreadyPartOfOrdinalsChannel,
  getAuthUrl,
  getStoredToken,
  getToken,
  getUserData,
  removeStoredToken,
  storeToken,
} from "../../utils/discordOAuthUtil";
import {
  assignPassportNumber,
  getAllActivations,
  getPassport,
  getPassportByVrf,
  getVrfNumber,
  registerPassport,
  removeVrfNumber,
  storeVrfNumber,
  updateItineraryStatusToInreview,
  updateUserInfo,
} from "../../apis/ordinalsPassportAPI";
import AssignPassportNumberDialog from "../../components/assign-passport-number/AssignPassportNumberDialog";
import axios from "axios";
import JoinDiscordChannelDialog from "../../components/join-channel/JoinDiscordChannelDialog";
import LeavingForActivityDialog from "../../components/leaving-for-activity/LeavingForActivityDialog";
import {
  connectTwitter,
  twitterOAuth,
  removeTwiterTokens,
  storeTwiterCode,
  getStoredTwiterCode,
} from "../../utils/twitterOAuthUtil";
import lol from "../../assets/png/Img-passport.png";
import SubmitButtonDialog from "../../components/submit-button/SubmitButtonDialog";
import ConfirmationDialog from "../../components/confirmation/ConfirmatonDialog";

const Layout = () => {
  const [showConnectToDiscordDialog, setShowConnectToDiscordDialog] =
    useState<boolean>(false);
  const [showJoinDiscordChannelDialog, setShowJoinDiscordChannelDialog] =
    useState<boolean>(false);
  const [showPassportDetailsDialog, setPassportDetailsDialog] =
    useState<boolean>(false);
  const [showAssignPassportNumberDialog, setShowAssignPassportNumberDialog] =
    useState<boolean>(false);
  const [showLeavingForActivityDialog, setShowLeavingForActivityDialog] =
    useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [currentActivityLink, setCurrentActivityLink] = useState<string>("");

  const [isDiscordConnected, setIsDiscordConnected] = useState<boolean>(false);
  const [userDiscordProfile, setUserDiscordProfile] = useState<any>(null);
  const [userPassportProfile, setUserPassportProfile] = useState<any>(null);
  const [userTwitterProdile, setUserTwitterProfile] = useState<any>(null);
  const [vrfDetails, setVrfDetails] = useState<any>(null);
  const [allActivities, setAllActivities] = useState<any>([]);
  const [showSubmitButtonDialog, setShowSubmitButtonDialog] =
    useState<boolean>(false);
  const passportDetails = useRef(null);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  let { vrfNumber, activityNumber } = useParams();
  const code = searchParams.get("code");
  const [authenticatingTwitter, setAuthenticatingTwitter] =
    useState<boolean>(false);
  const [discordProfileLoading, setDiscordProfileLoading] =
    useState<boolean>(false);

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    localStorage.setItem("verification", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    const storedData = localStorage.getItem("verification");
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);

  const verifyTask = (id: any) => {
    const newItem = { id: id, status: "completed" };
    setData([...data, newItem]);
    localStorage.setItem("verification", JSON.stringify([...data, newItem]));
  };

  const checkIfComplete = (id: any) => {
    const storedData = localStorage.getItem("verification");
    if (storedData) {
      const dataArray = JSON.parse(storedData);
      const item = dataArray.find(
        (item: any) => item.id === id && item.status === "completed"
      );
      return item ? true : false;
    }
  };

  console.log({ data });
  useEffect(() => {
    console.log({ vrfDetails });
    if (
      vrfDetails &&
      vrfDetails?.VRF &&
      (!vrfDetails?.email ||
        !vrfDetails?.xverseOrdinalAddress ||
        !vrfDetails?.evmAddress)
    ) {
      setShowSubmitButtonDialog(true);
    }
  }, [vrfDetails]);

  useEffect(() => {
    if (pathname.includes("/satoshi")) {
      if (vrfNumber) {
        storeVrfNumber(vrfNumber);
        navigate("/");
      }
    } else if (pathname.includes("/activity")) {
    } else if (
      pathname.includes("/twitter-callback") &&
      code &&
      !userTwitterProdile
    ) {
      storeTwiterCode(code);
      navigate("/");
    } else if (pathname.includes("/callback") && code && !userDiscordProfile) {
      fetchUserData(code);
    } else {
      fetchVrfDetails();
      if (!userDiscordProfile && getStoredToken()) {
        fetchUserData("");
      }
    }
    // eslint-disable-next-line
  }, [pathname, code, userDiscordProfile, vrfNumber]);

  useEffect(() => {
    if (!allActivities?.length) {
      fetchAllActivities();
    }
    // eslint-disable-next-line
  }, [allActivities]);

  useEffect(() => {
    if (allActivities?.length) {
      if (userPassportProfile?.activations?.length) {
        for (let i = 0; i < allActivities?.length; i++) {
          const userActivity = userPassportProfile?.activations.find(
            (activity: any) =>
              activity.activityId === allActivities[i].activityId
          );
          if (userActivity) {
            allActivities[i] = { ...userActivity };
          }
        }
      }
      for (let i = 0; i < allActivities?.length; i++) {
        allActivities[i] = {
          ...allActivities[i],
          isExpanded:
            allActivities[i].activityId === activityNumber ? true : false,
        };
      }
      setAllActivities([...allActivities]);
    }
    // eslint-disable-next-line
  }, [userPassportProfile, activityNumber]);

  const fetchAllActivities = async () => {
    let result = await getAllActivations()
      .then((response) => {
        // console.log(response.data.body.activations);
        return response.data.body.activations;
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.data);
        return [];
      });
    for (let i = 0; i < result?.length; i++) {
      result[i] = { ...result[i], isExpanded: false };
    }
    setAllActivities([...result]);
  };

  const fetchVrfDetails = async () => {
    let vrfNumber = getVrfNumber();
    if (vrfNumber) {
      const vrfDetailsResponse = await getPassportByVrf(vrfNumber)
        .then((response) => {
          return response.data.body.passport;
        })
        .catch((error) => {
          return undefined;
        });
      if (vrfDetailsResponse) {
        setVrfDetails({ ...vrfDetailsResponse });
      } else {
        setVrfDetails(undefined);
      }
    } else {
      setVrfDetails(undefined);
    }
  };

  const registerTwitterUser = async (discordId: string) => {
    let twitterCode = getStoredTwiterCode();
    if (twitterCode && discordId) {
      setAuthenticatingTwitter(true);
      const userProfile = await connectTwitter(twitterCode, discordId);
      setAuthenticatingTwitter(false);
      removeTwiterTokens();
    }
  };

  const registerPassortWithDiscordAccount = async (
    discordId: string,
    vrf: string
  ) => {
    await registerPassport(discordId, vrf)
      .then((response) => {
        removeVrfNumber();
      })
      .catch((error) => {
        removeVrfNumber();
      });
  };

  const getUserPassortDetails = async (passportId: string) => {
    setDiscordProfileLoading(true);
    let result = await getPassport(passportId)
      .then((response) => {
        // console.log(response.data.body);
        return response.data.body;
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error?.response.data);
        return undefined;
      });
    if (!result?.userTwitterProfile?.id && getStoredTwiterCode()) {
      await registerTwitterUser(passportId);
      result = await getUserPassortDetails(passportId);
    }
    setDiscordProfileLoading(false);
    return result;
  };

  const fetchUserData = async (code: string) => {
    try {
      const token: string | null = getStoredToken();
      // console.log("token", token);
      if (token !== "undefined" && token && typeof token === "string") {
        const userData = await getUserData(token);
        // console.log(userData)
        const userPassportDataResponse = await getUserPassortDetails(
          userData.id
        );
        let vrfNumber = getVrfNumber();
        if (userPassportDataResponse) {
          setUserPassportProfile({ ...userPassportDataResponse });
          removeVrfNumber();
        } else if (userData?.id && vrfNumber) {
          setUserPassportProfile(undefined);
          await registerPassortWithDiscordAccount(userData?.id, vrfNumber);
          const userPassportDataResponse = await getUserPassortDetails(
            userData.id
          );
          setUserPassportProfile({ ...userPassportDataResponse });
          removeTwiterTokens();
        }
        setUserDiscordProfile(userData);
        setIsDiscordConnected(true);
        if (!pathname.includes("itinerary")) {
          navigate("/");
        }
      } else if (code) {
        const tokenResponse = await getToken(code);
        // console.log("tokenResponse.access_token", tokenResponse?.access_token);
        if (tokenResponse?.access_token) {
          storeToken(tokenResponse.access_token);
        }
        navigate("/");
      }
    } catch (error) {
      logoutDiscord();
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("storage", storageEventHandler, false);
    // eslint-disable-next-line
  }, []);

  function storageEventHandler() {
    const token = getStoredToken();
    const vrf = getVrfNumber();
    if (!token && !vrf) {
      logoutDiscord();
    } else if (!userDiscordProfile) {
      fetchUserData("");
    }
  }

  const logoutDiscord = () => {
    setUserDiscordProfile(undefined);
    setUserPassportProfile(undefined);
    setIsDiscordConnected(false);
    removeStoredToken();
    removeTwiterTokens();
    removeVrfNumber();
    navigate("/");
  };

  const handleOpenCloseItinerary = (activityId: number) => {
    for (let i = 0; i < allActivities.length; i++) {
      if (activityId === allActivities[i].activityId) {
        allActivities[i].isExpanded = !allActivities[i].isExpanded;
      }
    }
    setAllActivities([...allActivities]);
  };

  const closeDiscordConnectDialog = () => {
    setShowConnectToDiscordDialog(false);
  };

  const openPassportDetailsDialog = () => {
    setPassportDetailsDialog(true);
  };

  const closePassportDetailsDialog = () => {
    setPassportDetailsDialog(false);
  };

  const openConnectDiscordDialog = () => {
    setShowConnectToDiscordDialog(true);
  };

  const closeLeavingForActivityDialog = () => {
    setShowLeavingForActivityDialog(false);
  };

  const openLeavingForActivityDialog = () => {
    setShowLeavingForActivityDialog(true);
  };

  const connectDiscord = () => {
    window.location.href = getAuthUrl();
  };

  const redirectToTwitterLogin = () => {
    window.location.href = twitterOAuth();
  };

  const moveItineraryToInReview = async (
    activityId: string,
    password?: string,
    activityLink?: string,
    isTaskClicked?: boolean,
    taskAction?: string
  ) => {
    if (userDiscordProfile?.id && activityId) {
      // let token = getStoredToken();
      const isPartOfChannel =
        // await checkUserIfUserIsAlreadyPartOfOrdinalsChannel(token!);
        // if (!isPartOfChannel) {
        //   openJoinDiscordChannelDialog();
        //   return;
        // }
        await updateItineraryStatusToInreview(
          userDiscordProfile?.id,
          activityId,
          isTaskClicked,
          taskAction
        );
      const userPassportDataResponse = await getPassport(userDiscordProfile?.id)
        .then((response) => {
          // console.log(response.data.body);
          return response.data.body;
        })
        .catch((error) => {
          // console.log(error);
          // console.log(error?.response.data);
          return undefined;
        });
      setUserPassportProfile({ ...userPassportDataResponse });
      // setCurrentPassword(password);
      // setCurrentActivityLink(activityLink);
      // openLeavingForActivityDialog();
    }
  };

  const openShowAssignPassportNumberDialog = () => {
    setShowAssignPassportNumberDialog(true);
  };

  const closeShowAssignPassportNumberDialog = () => {
    setShowAssignPassportNumberDialog(false);
  };

  const openJoinDiscordChannelDialog = () => {
    setShowJoinDiscordChannelDialog(true);
  };

  const closeJoinDiscordChannelDialog = () => {
    setShowJoinDiscordChannelDialog(false);
  };

  const joinChannel = () => {
    setShowJoinDiscordChannelDialog(false);
    window.open(
      "https://discord.gg/Pw4YgSAFGC",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const updatePassportNumber = async (passportId: number) => {
    if (
      passportId >= 0 &&
      passportId <= 4999 &&
      (vrfDetails?.VRF || userPassportProfile?.VRF)
    ) {
      const result = await assignPassportNumber(
        vrfDetails?.VRF ? vrfDetails?.VRF : userPassportProfile?.VRF,
        passportId
      )
        .then((response) => {
          console.log(response.data);
          toast.success("Nice snag! I bet you're also a great trader.");
          return true;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          console.log(error.response.data.status.message);
          toast.error("Too slow! You've been front-run. Try again");
          return false;
        });
      if (result) {
        await fetchVrfDetails();
        await fetchUserData("");
        setShowAssignPassportNumberDialog(false);
      }
    }
  };

  const goToPassportDetails = () => {
    //@ts-ignore
    passportDetails?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getMyTier = () => {
    if (data?.length) {
      const activations = data?.filter(
        (activity: any) => activity.status === "completed"
      );
      if (activations.length >= 8) {
        return "2";
        // } else if (activations.length >= 8) {
        //   return "2";
      } else if (activations.length >= 4) {
        return "1";
      }
      if (userPassportProfile?.passportId >= 0) {
        return "0";
      }
    }
    return "0";
  };

  const updateUserProfileByVRF = async (data: any) => {
    try {
      const vrfNumber = getVrfNumber();
      console.log("data", vrfNumber, userPassportProfile?.VRF);
      if (userPassportProfile?.VRF || vrfNumber) {
        const vrf: number | any = userPassportProfile?.VRF;
        await updateUserInfo(vrfNumber || vrf, data);
        await fetchVrfDetails();
        setShowSubmitButtonDialog(false);
        setShowConfirmationDialog(true);
      } else {
        toast.error("VRF not found");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed. Please try again");
    }
  };

  // console.log(vrfDetails);
  // console.log(activityNumber)
  // console.log(userDiscordProfile);
  // console.log(showAssignPassportNumberDialog);
  console.log(userPassportProfile);
  console.log(getVrfNumber());

  if (
    !getVrfNumber() &&
    !userDiscordProfile?.id &&
    !authenticatingTwitter &&
    !discordProfileLoading
  ) {
    return (
      <div className="w-full">
        <div className="landing-page">
          <main>
            <div className="welcome-box">
              <h2>Overview</h2>
              <p>
                The Ordinals Passport Phygital will help guide you through a
                curated and immersive journey through NFC | Ordinals Lisbon. For
                each item listed below, you will need to:
              </p>
              <ul>
                <li>
                  - Go to physical Activation locations that will be published
                  on @ordinalpassport discord
                </li>
                <li>- Scan QR code at Crypto Immigration checkpoints</li>
                <li>- Take pics, videos and complete 1-3 social share tasks</li>
                <li>- Submit your itinerary for review</li>
              </ul>

              <h2>Rewards</h2>
              <p>
                We have exciting rewards including invites to a FCFS Yacht party
                for those who complete the 26 activations. We also have rewards
                ranging from $1K to nearly $20K, depending on your progress.
              </p>

              <h2>First Steps</h2>
              <p>Scan the QR code in your physical Passport ID page: </p>
              <img className="full-width-image" src={lol} alt="Landing" />
            </div>
          </main>
        </div>
      </div>
    );
  }
  return (
    <div className="h-screen w-ful font-pt-mono no-down">
      {/* <div className="flex justify-center mt-6">
        <div className=" w-full max-w-md">
          <CKEditor
            editor={ClassicEditor}
            data="<p>Hello from CKEditor&nbsp;5!</p>"
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              console.log(editor.getData());
            }}
          />
        </div>
      </div> */}
      <Toaster
        toastOptions={{
          // Define default options
          duration: 10000,
        }}
      />
      <div className="flex justify-center">
        <Header />
      </div>
      <div className="flex justify-center mt-6">
        <UserInfromation
          username={userDiscordProfile?.username}
          id={userDiscordProfile?.id}
          avatar={userDiscordProfile?.avatar}
        />
      </div>
      <div className="flex justify-center mt-6">
        <div>
          <img
            src={SVGS.FerrumNetworkLogoBlack}
            alt="ferrum-network-logo"
          ></img>
        </div>
      </div>
      <div className="flex justify-center mt-6">
        <div className="w-full max-w-md">
          <div className="rounded-xl bg-[url('/src/assets/png/background-banner.png')] bg-cover bg-no-repeat bg-center bg-opacity-4 h-36 w-full">
            <div className="flex flex-col items-center w-full text-center">
              <div className="text-[14px] text-white font-bold font-['Montserrat'] px-6 mt-8 ml-4">
                Sign up for the Oridnals Night
              </div>
              <div className="text-[14px] text-white font-bold font-['Montserrat'] px-[60px]">
                Inscribing Nashville Official After Party for FREE!
              </div>
              <a
                href="https://lu.ma/ordinals-night"
                className="text-[14px] text-white font-bold font-['Montserrat'] mr-5"
              >
                👉 <span className="hover:underline">Sign up</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {userPassportProfile &&
        (!userPassportProfile?.email ||
          !userPassportProfile?.xverseOrdinalAddress ||
          !userPassportProfile?.evmAddress) && (
          <div
            className="flex justify-center mt-6 cursor-pointer"
            onClick={() => {
              setShowSubmitButtonDialog(true);
            }}
          >
            <div className="w-full max-w-md cursor-pointer">
              <div className="w-full max-w-md pl-2 pr-2 cursor-pointer">
                <div className="w-full flex justify-between rounded-xl bg-[#ea5218] bottom-border-v5 pr-4 cursor-pointer">
                  <div className="flex justify-center items-center p-2 w-full cursor-pointer">
                    <label
                      style={{ color: "white" }}
                      className="w-full font-montserrat font-bold text-[12px] leading-4 text-center cursor-pointer"
                    >
                      Claim All Ordinals Passport
                      <br /> Airdrops, Raffles, Free Mints and more{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {!userDiscordProfile?.id ? (
        <div className="flex justify-center mt-6">
          <div className=" w-full max-w-md pl-2 pr-2">
            <button
              className="flex items-center justify-between bg-gradient-to-b from-orange-400 to-orange-600 rounded-[10px] h-[60px] !p-4 w-full"
              onClick={() => {
                openConnectDiscordDialog();
              }}
            >
              <img
                src={SVGS.ConnectButtonV2}
                alt="connect button"
                className="w-5 h-5"
              />
              <div className="text-white text-sm w-full font-bold font-['Montserrat'] capitalize">
                CONNECT TO SAVE YOUR PASSPORT NUMBER
              </div>
            </button>
          </div>
        </div>
      ) : undefined}
      <div className="flex justify-center mt-6">
        <Passport
          openConnectDiscordDialog={openConnectDiscordDialog}
          isDiscordConnected={isDiscordConnected}
          openPassportDetailsDialog={openPassportDetailsDialog}
          passportId={userPassportProfile?.passportId}
          scanPassportId={vrfDetails?.passportId}
          setShowAssignPassportNumberDialog={openShowAssignPassportNumberDialog}
          goToPassportDetails={goToPassportDetails}
        />
      </div>
      <div className="flex justify-center mt-6">
        <TierInformation
          isDiscordConnected={isDiscordConnected}
          totalActivations={allActivities.length}
          completedActivations={
            data?.filter((activity: any) => activity.status === "completed")
              .length
          }
          getMyTier={getMyTier}
        />
      </div>
      {!userPassportProfile || userPassportProfile?.userTwitterProfile?.id ? (
        <div className="flex justify-center mt-1">
          <div className="w-full max-w-md">
            <div className="mt-2 flex justify-center w-full max-w-md">
              <Collapsible
                title={"Start Here"}
                description={""}
                isExpanded={false}
                id={"99"}
                handleOpenCloseItinerary={() => {}}
                icon={""}
                rewards={""}
                task={""}
                status={""}
                password={""}
                activityLink={""}
                currentLocationActivityId={""}
                moveItineraryToInReview={() => {}}
                data={data}
                checkIfComplete={checkIfComplete}
                isStatic={true}
                verifyTask={verifyTask}
                isCompleted={checkIfComplete("99")}
              />
            </div>
            {allActivities.map((itinerary: any, index: any) => {
              return (
                <div
                  className="mt-2 flex justify-center w-full max-w-md"
                  key={index}
                >
                  <Collapsible
                    title={itinerary.title}
                    description={itinerary.description}
                    isExpanded={itinerary.isExpanded}
                    id={itinerary.activityId}
                    handleOpenCloseItinerary={handleOpenCloseItinerary}
                    icon={itinerary.icon}
                    rewards={itinerary.reward}
                    task={itinerary.task}
                    status={itinerary.status}
                    password={itinerary.password}
                    activityLink={itinerary.activityLink}
                    currentLocationActivityId={
                      activityNumber ? activityNumber : ""
                    }
                    data={data}
                    checkIfComplete={checkIfComplete}
                    moveItineraryToInReview={moveItineraryToInReview}
                    isStatic={false}
                    verifyTask={verifyTask}
                    isCompleted={checkIfComplete(itinerary.activityId)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-center mt-6 pl-2 pr-2">
            <div className=" w-full max-w-md inline-flex items-center justify-center pl-2 pr-2">
              {/* <div className="">
              <label className="font-montserrat font-bold text-[10px] leading-3">
                ITINERARY
              </label>
            </div> */}
              {!userPassportProfile ||
              userPassportProfile?.userTwitterProfile?.id ? (
                <div> </div>
              ) : (
                <div
                  className="w-full flex justify-center items-center curser-pointer bg-[#e93f00] rounded cursor-pointer"
                  onClick={() => {
                    redirectToTwitterLogin();
                  }}
                >
                  <div className="flex justify-center">
                    <img
                      src={SVGS.TwitterLogo}
                      className=""
                      alt="twitter-logo"
                    />
                    <div className="w-full max-w-md p-2 curser-pointer font-montserrat font-bold text-[12px] leading-4 text-white">
                      Connect Twitter
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <div className="w-full max-w-md pl-2 pr-2">
              <div className="w-full flex justify-between rounded-xl bg-[#f5a98c] bottom-border-v5 pr-4">
                <img src={SVGS.BigBitcoinLogo} />
                <div className="flex justify-center items-center">
                  <div>
                    <div>
                      <label className="font-montserrat font-bold text-[12px] leading-4">
                        Connect your Twitter account!{" "}
                      </label>
                    </div>
                    <div>
                      <label className="font-montserrat font-normal text-[12px] leading-4">
                        Your activity itinerary unlocks when your twitter is
                        connected
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {vrfDetails &&
        vrfDetails?.VRF &&
        (!vrfDetails?.email ||
          !vrfDetails?.xverseOrdinalAddress ||
          !vrfDetails?.evmAddress) && (
          <div className="flex justify-center mt-6">
            <div className="w-full max-w-md pl-2 pr-2">
              <button
                className="flex items-center justify-between bg-gradient-to-b from-neutral-800 to-black rounded-[10px] h-[60px] !p-4 w-full"
                onClick={() => {
                  setShowSubmitButtonDialog(true);
                }}
              >
                <img
                  src={SVGS.SubmitIcon}
                  alt="connect button"
                  className="w-5 h-5"
                />
                <div className="text-white text-sm font-bold font-['Montserrat'] uppercase w-full">
                  Lock in your completed itinerary
                </div>
              </button>
            </div>
          </div>
        )}
      <div className="flex justify-center mt-6" ref={passportDetails}>
        <UserDetails />
      </div>
      <div className="flex justify-center mt-6">
        <Footer />
      </div>
      {isDiscordConnected && (
        <div className="flex justify-center mt-6 ">
          <div
            className="w-full max-w-md flex justify-center font-montserrat text-[10px] font-bold leading-3 cursor-pointer mb-6"
            onClick={() => {
              logoutDiscord();
            }}
          >
            Logout
          </div>
        </div>
      )}
      {showConnectToDiscordDialog && (
        <ConnectDiscordDialog
          show={showConnectToDiscordDialog}
          onHide={closeDiscordConnectDialog}
          connnectDiscord={connectDiscord}
        />
      )}
      {showPassportDetailsDialog && (
        <PassportDetailsDialog
          show={showPassportDetailsDialog}
          onHide={closePassportDetailsDialog}
          passportId={userPassportProfile?.passportId}
          vrf={userPassportProfile?.VRF}
        />
      )}

      {showAssignPassportNumberDialog && (
        <AssignPassportNumberDialog
          show={showAssignPassportNumberDialog}
          onHide={closeShowAssignPassportNumberDialog}
          vrf={vrfDetails?.VRF}
          updatePassportNumber={updatePassportNumber}
        />
      )}

      {showJoinDiscordChannelDialog && (
        <JoinDiscordChannelDialog
          show={showJoinDiscordChannelDialog}
          onHide={closeJoinDiscordChannelDialog}
          joinChannel={joinChannel}
        />
      )}

      {showSubmitButtonDialog && (
        <SubmitButtonDialog
          show={showSubmitButtonDialog}
          onHide={() => setShowSubmitButtonDialog(false)}
          submitAction={(data) => updateUserProfileByVRF(data)}
        />
      )}
      {showConfirmationDialog && (
        <ConfirmationDialog
          show={showConfirmationDialog}
          onHide={() => setShowConfirmationDialog(false)}
        />
      )}
      {showLeavingForActivityDialog && (
        <LeavingForActivityDialog
          show={showLeavingForActivityDialog}
          onHide={closeLeavingForActivityDialog}
          password={currentPassword}
          activityLink={currentActivityLink}
        />
      )}
    </div>
  );
};

export default Layout;
