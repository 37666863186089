import React from "react";

const UserDetails = () => {
  return (
    <div className="p-2 w-full max-w-md">
      <div className="bottom-border-v4 rounded-xl p-4 bg-[url('/src/assets/svg/btc-logo-xl.svg')] bg-contain bg-no-repeat bg-center">
        <div>
          <div>
            <label className="font-pt-mono font-normal leading-4 text-[14px]">
              Official Name
            </label>
          </div>
          <div>
            <label className="font-orca leading-5 font-normal text-[20px]">
              SATOSHI NAKAMOTO
            </label>
          </div>
        </div>

        <div className="mt-4">
          <div>
            <label className="font-pt-mono font-normal leading-4 text-[14px]">
              Birthday
            </label>
          </div>
          <div>
            <label className="font-orca leading-5 font-normal text-[20px]">
              OCTOBER 31, 2008
            </label>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <label className="font-pt-mono font-normal leading-4 text-[14px]">
              Nationality
            </label>
          </div>
          <div>
            <label className="font-orca leading-5 font-normal text-[20px]">
              DECENTRALIZED CITIZENS
            </label>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <label className="font-pt-mono font-normal leading-4 text-[14px]">
              Place of Birth
            </label>
          </div>
          <div>
            <label className="font-orca leading-5 font-normal text-[20px]">
              DECENTRALIZED STATES OF WEB3
            </label>
          </div>
        </div>
      </div>
      <div className="bottom-border-v4 rounded-xl p-4 mt-6">
        <div className="flex justify-between">
          <div>
            <div>
              <label className="font-pt-mono font-normal leading-4 text-[14px]">
                Date of Issue
              </label>
            </div>
            <div>
              <label className="font-orca leading-5 font-normal text-[20px]">
                28-MAY-2024
              </label>
            </div>
          </div>
          <div>
            <div>
              <label className="font-pt-mono font-normal leading-4 text-[14px]">
                Date of Expiration
              </label>
            </div>
            <div>
              <label className="font-orca leading-5 font-normal text-[20px]">
                ETERNITY
              </label>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div>
            <label className="font-pt-mono font-normal leading-4 text-[14px]">
              Authority
            </label>
          </div>
          <div>
            <label className="font-orca leading-5 font-normal text-[20px]">
              SEIZECTRL - SELF SOVEREIGN
            </label>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <label className="font-pt-mono font-normal leading-4 text-[14px]">
              Issuer
            </label>
          </div>
          <div>
            <label className="font-orca leading-5 font-normal text-[20px]">
              FERRUM NETWORK
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
