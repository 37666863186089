import React, { useState } from "react";
import { Dialog } from "../dialog/Dialog";
import SVGS from "../../assets/svg";
import { copyTextToClipboard } from "../../utils/helpers";
import toast from "react-hot-toast";

interface ConnectDiscordDialogIProps {
  show: boolean;
  onHide: () => void;
  vrf: string;
  updatePassportNumber: any;
}

const AssignPassportNumberDialog = ({
  show,
  onHide,
  vrf,
  updatePassportNumber,
}: ConnectDiscordDialogIProps) => {
  const [passportNumber, setPassportNumber] = useState<string>("");
  return (
    <>
      {show && (
        <Dialog
          title="ASSIGN PASSPORT NUMBER"
          show={show}
          onHide={onHide}
          showCloseButton={true}
          size="lg"
        >
          <div className="mt-8">
            <div className="mt-4">
              <div>
                <label className="font-pt-mono text-[14px] font-normal leading-3 text-white">
                  Passport Address:
                </label>
              </div>
              <div className="flex justiy-center">
                <label className="font-monterrat text-[30px] font-bold leading-9 text-white">
                  {vrf ? `${vrf.substring(0, 15)}...` : ""}
                </label>
                <img
                  className="ml-4 cursor-pointer"
                  src={SVGS.CopyIcon}
                  alt="copy-icon"
                  onClick={() => {
                    copyTextToClipboard(vrf);
                    toast.success("Copied");
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <div>
                <div className="ml-1 ">
                <label className="font-pt-mono text-[14px] font-normal leading-3 text-white">
                  Passport Number:
                </label>
                <br />
                <label className="font-pt-mono text-[12px] font-normal leading-1 text-white">
                  Satoshi is watching... Select your passport number (0-4999) wisely to unlock the mysteries of the blockchain.
                </label>
                </div>
                <div className="mt-4">
                  <input
                    value={passportNumber}
                    onChange={(e) => {
                      e.preventDefault();
                      setPassportNumber(e.target.value);
                    }}
                    type="text"
                    className=" border border-white text-sm rounded-lg block w-full p-2.5 bg-black  dark:text-white "
                  />
                </div>
              </div>
            </div>
            <div
              className="rounded-xl bg-[#e93f00] mt-4 cursor-pointer"
              onClick={() => {
                console.log(Number.isNaN(Number(passportNumber)));
                if (
                  passportNumber === "" ||
                  passportNumber === undefined ||
                  passportNumber === null ||
                  Number.isNaN(Number(passportNumber))
                ) {
                  toast.error("Please enter a numeric value");
                } else if (
                  Number(passportNumber) > 4999 ||
                  Number(passportNumber) < 0
                ) {
                  toast.error(
                    "Number can not be greater than 4999 or less than 0"
                  );
                } else {
                  updatePassportNumber(passportNumber);
                }
              }}
            >
              <div className="flex justify-center items-center p-4 cursor-pointer text-white">
                <label className="ml-2 font-montserrat font-bold text-[16px] leading-5 cursor-pointer">
                  Assign Passport Number
                </label>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default AssignPassportNumberDialog;
