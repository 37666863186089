import OridinalPassportLogo from "./ordinals-pssport-logo.svg";
import FerrumNetworkLogoBlack from "./ferrum-network-logo-black.svg";
import FerrumNetworkLogoWhite from "./ferrum-network-logo-white.svg";
import ImagePlaceHolder from "./image-placeholder.svg";
import EditTextIcon from "./edit-text-icon.svg";
import BtcLogo from "./btc-logo.svg";
import ViewPassportAddressIcon from "./view-passport-address-icon.svg";
import ViewPassportDetailsIcon from "./view-passport-details-icon.svg";
import TrophyIcon from "./trophy-icon.svg";
import Itinerary1 from "./itinerary-1.svg";
import Itinerary2 from "./itinerary-2.svg";
import Itinerary3 from "./itinerary-3.svg";
import DropDownCloseIcon from "./drop-down-close-icon.svg";
import DropDownOpenIcon from "./drop-down-open-icon.svg";
import FerrumNetworkFooterLogo from "./ferrum-network-footer-logo.svg";
import CialFooterLogo from "./cial-footer-logo.svg";
import PizzaNinjasFooterLogo from "./pizza-ninjas-footer-logo.svg";
import BtcLogoXl from "./btc-logo-xl.svg";
import CloseIcon from "./close-icon.svg";
import DiscordLogo from "./discord-logo.svg";
import ChainLinkLogo from "./chain-link-logo.svg";
import ShareIcon from "./share-icon.svg";
import CopyIcon from "./copy-icon.svg";
import LocationIcon from "./location-icon.svg";
import ActivityIcon from "./activity-icon.svg";
import TwitterLogo from "./twitter-logo.svg";
import BigBitcoinLogo from "./big-bitcoin-logo.svg";
import ConnectButtonV2 from "./connect-button-2.svg";
import SubmitIcon from "./submit-icon.svg";
import SubmitLogo from "./submit-logo.svg";
import SunIcon from "./sun-icon.svg";

const SVGS = {
  OridinalPassportLogo,
  FerrumNetworkLogoBlack,
  ImagePlaceHolder,
  EditTextIcon,
  BtcLogo,
  ViewPassportAddressIcon,
  ViewPassportDetailsIcon,
  FerrumNetworkLogoWhite,
  TrophyIcon,
  Itinerary1,
  Itinerary2,
  Itinerary3,
  DropDownCloseIcon,
  DropDownOpenIcon,
  FerrumNetworkFooterLogo,
  CialFooterLogo,
  PizzaNinjasFooterLogo,
  BtcLogoXl,
  CloseIcon,
  DiscordLogo,
  ChainLinkLogo,
  ShareIcon,
  CopyIcon,
  LocationIcon,
  ActivityIcon,
  TwitterLogo,
  BigBitcoinLogo,
  ConnectButtonV2,
  SubmitIcon,
  SubmitLogo,
  SunIcon,
};

export default SVGS;
