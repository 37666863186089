import axios from "axios";

export const BASE_URL = "https://api-ordinal-passport.svcs.ferrumnetwork.io/v1";
// export const BASE_URL = "http://localhost:8088/v1";
const REGISTER_PASSPORT = "/passport/register";
const USER_INFO = "/passport/profile/vrf/";
const GET_PASSSPORT = "/passport/discord/";
const GET_ACTIVATIONS = "/activity";
const UPDATE_ACTIVATIONS = "/passport/";
const GET_PASSSPORT_BY_VRF = "/passport/vrf/";
const UPDATE_PASSPORT_NUMBER = "/passport/vrf/";

export const registerPassport = async (discordId: string, vrf: string) => {
  return axios.post(`${BASE_URL}${REGISTER_PASSPORT}?type=BTC-Nashville`, {
    discordId,
    vrf,
  });
};

export const getPassport = async (discordId: string) => {
  return axios.get(`${BASE_URL}${GET_PASSSPORT}${discordId}`);
};

export const getPassportByVrf = async (vrf: string) => {
  return axios.get(`${BASE_URL}${GET_PASSSPORT_BY_VRF}${vrf}`);
};

export const assignPassportNumber = async (vrf: string, passportId: number) => {
  return axios.patch(`${BASE_URL}${UPDATE_PASSPORT_NUMBER}${vrf}`, {
    passportId,
  });
};

export const getAllActivations = async () => {
  return axios.get(`${BASE_URL}${GET_ACTIVATIONS}?type=BTC-Nashville`);
};

export const updateProfilePicture = async (discordId: string, image: File) => {
  let formData = new FormData();
  formData.append("image", image);
  return axios.post(`${BASE_URL}/passport/${discordId}/update/pfp`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateItineraryStatusToInreview = async (
  discordId: string,
  activityId: string,
  isTaskClicked?: boolean,
  taskAction?: string
) => {
  return axios.patch(`${BASE_URL}${UPDATE_ACTIVATIONS}${discordId}`, {
    activityId,
    isTaskClicked,
    taskAction,
  });
};

export const storeVrfNumber = (vrfNumber: string) => {
  localStorage.setItem("vrf_number", vrfNumber);
};

export const getVrfNumber = () => {
  return localStorage.getItem("vrf_number");
};

export const removeVrfNumber = () => {
  // console.log("removing number")
  localStorage.removeItem("vrf_number");
};

export const updateUserInfo = (vrfNumber: number, data: any) => {
  return axios.patch(`${BASE_URL}${USER_INFO}${vrfNumber}`, data);
};
