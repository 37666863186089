import React from "react";
import SVGS from "../../assets/svg";
interface PassportIProps {
  openConnectDiscordDialog: () => void;
  openPassportDetailsDialog: () => void;
  isDiscordConnected: boolean;
  passportId: string;
  scanPassportId:string;
  setShowAssignPassportNumberDialog:any;
  goToPassportDetails:any;
}
const Passport = ({
  openConnectDiscordDialog,
  isDiscordConnected,
  openPassportDetailsDialog,
  passportId,
  scanPassportId,
  setShowAssignPassportNumberDialog,
  goToPassportDetails
}: PassportIProps) => {
  return (
    <div className="w-full max-w-md pl-2 pr-2 flex justify-between">
      <div className="p-2 mr-2 w-1/2 rounded-xl bg-[#E94000]">
        <div className="text-md-normal-custom-v2 flex justify-center mt-1 bottom-border-v2">
          <div className="mb-2">
            <label>Passport Number</label>
          </div>
        </div>
        <div className={`text-lg-bold-custom-v2 flex justify-center ${Number(passportId) >=0  ? passportId : Number(scanPassportId)>=0 ? "" : "blur-sm" } `} >
          <div className="font-montserrat">
            <label>{Number(passportId) >=0  ? passportId : Number(scanPassportId)>=0 ? scanPassportId : "21"}</label>
          </div>
        </div>
        {isDiscordConnected ? (
          <div className="flex justify-center">
            <img src={SVGS.ChainLinkLogo} alt="chain-link-logo" />
            <div className="ml-2">
              <span>
                <label className="font-orca text-[8.3px] font-normal leading-3 text-white small-line-height">
                  {"POWERED BY \n"}
                </label>
              </span>
              <span>
                <label className="font-orca text-[9.86px] font-normal leading-3 text-white small-line-height">
                  CHAINLINK VRF
                </label>
              </span>
            </div>
          </div>
        ) : (
          <div
            className="rounded p-1 text-md-normal-custom-v5 bg-black cursor-pointer flex justify-center text-center"
            onClick={() => {
              if((scanPassportId && Number(scanPassportId)  === -1) || (passportId && Number(passportId) === -1)){
                setShowAssignPassportNumberDialog();
              }else{
                openConnectDiscordDialog();
              }
              
            }}
          >
            <div className="cursor-pointer">
              <label className="cursor-pointer">{(scanPassportId && Number(scanPassportId)  === -1) || (passportId && Number(passportId) === -1) ? "CLICK TO CHOOSE YOUR PASSPORT NUMBER" : "CONNECT TO CLAIM PASSPORT"}</label>
            </div>
          </div>
        )}
      </div>

      <div className="ml-2 w-1/2 ">
        <div
          className="rounded-xl pl-2 pr-2 pt-1 pb-1 p h-1/2  bg-black flex items-center cursor-pointer"
          onClick={() => {
            if (isDiscordConnected) {
              openPassportDetailsDialog();
            } else {
              openConnectDiscordDialog();
            }
          }}
        >
          <div className="flex  text-center cursor-pointer">
            <div className="pl-1 pr-1 mr-1 rounded bg-[#E94000] flex items-center cursor-pointer">
              <img
                className="cursor-pointer"
                src={SVGS.ViewPassportAddressIcon}
                alt="view-passport-icon"
              />
            </div>
            <div className="text-md-normal-custom-v2 cursor-pointer">
              <label className="cursor-pointer">View Passport Address</label>
            </div>
          </div>
        </div>
        <div className="rounded-xl pl-2 pr-2 pt-1 pb-1 h-1/2 bg-[url('/src/assets/svg/btc-logo.svg')] bg-contain bg-no-repeat bg-center mt-1 bg-[#E9400026] bg-opacity-5 flex items-center cursor-pointer" onClick={()=>{
          goToPassportDetails();
        }}>
          <div className="flex  text-center cursor-pointer">
            <div className="pl-1 pr-1 mr-1 rounded bg-black flex items-center cursor-pointer">
              <img
                className="cursor-pointer"
                src={SVGS.ViewPassportDetailsIcon}
                alt="view-passport-icon"
              />
            </div>
            <div className="text-md-normal-custom-v4 text-black cursor-pointer">
              <label className="cursor-pointer">See Passport Details</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Passport;
