import React from "react";
import SVGS from "../../assets/svg";

interface UserInfromationIProps {
  username :string;
  avatar :string;
  id: string;
}
const UserInfromation = ({username,id,avatar}:UserInfromationIProps) => {
  return (
    <div className="w-full max-w-md">
      <div className="ml-6 mr-6 flex justify-center">
        <div className=" w-1/3 rounded">
          <img className="rounded-xl profile-pic" src={avatar ? `https://cdn.discordapp.com/avatars/${id}/${avatar}.png` : SVGS.ImagePlaceHolder} alt="user profile" />
        </div>

        <div className="pl-8 w-2/3">
          <div className="text-xs-custom flex">
            <div className="w-1/2">
              <label>Type: D</label>
            </div>
            <div className="w-1/2">
              <label>Code: SATS</label>
            </div>
          </div>
          <div className="text-md-normal-custom flex mt-4">
            <div>
              <label>Welcome</label>
            </div>
          </div>
          <div className="text-lg-bold-custom flex mt-1 bottom-border-v1">
            <div>
              <label>SATOSHI NAKAMOTO</label>
            </div>
          </div>
          <div className="text-md-normal-custom flex mt-4">
            <div>
              <label>Web3 Name</label>
            </div>
          </div>
          <div className=" flex justify-between mt-1 bottom-border-v1">
            <div className={`${username ? "text-lg-bold-custom" : "text-xs-custom-place-holder"} flex justify-end`}>
              <label>{username ? username : "Claim passport to show username"}</label>
            </div>
            {/* <div className="mb-1 cursor-pointer">
              <img className="cursor-pointer" src={SVGS.EditTextIcon} alt="edit-name-icon" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfromation;
