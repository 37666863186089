import React from "react";
import "./App.css";
import Layout from "./pages/layout/Layout";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Layout />,
          },
          {
            path: "satoshi/:vrfNumber",
            element: <Layout />,
          },
          {
            path: "itinerary/:activityNumber",
            element: <Layout />,
          },
          {
            path: "callback",
            element: <Layout />,
          },
          {
            path: "twitter-callback",
            element: <Layout />,
          },
          {
            path: "*",
            element: <Navigate to="/" replace />,
          },
        ],
      },
    ],
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
