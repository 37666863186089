import React from "react";
import SVGS from "../../assets/svg";
import lol from "../../assets/png/pn.png";

const Footer = () =>{

    return <div className="pt-2 pb-2 w-full max-w-md mb-8">
        <div className="flex justify-between pr-8 pl-8 ">
            <img src={SVGS.CialFooterLogo} alt="cial-logo"/>
            <img src={SVGS.FerrumNetworkFooterLogo} alt="ferrum-network-logo"/>
            <img src={lol} alt="pizza-ninjas-logo"/>
        </div>
        <div className="mt-6">
            <div className="flex justify-center items-center">
                <label className="font-orca text-[10px] font-normal leading-3">{">>>>> Satoshi Nakamoto >> NO ORDINARY << BITCOIN <<<<<"}</label>
            </div>
            <div className="flex justify-center items-center">
                <label className="font-orca text-[10px] font-normal leading-3">{">>>>> 21000000BTC20100522W0RKP33RBIP420ILOVEFRM <<<<<"}</label>
            </div>
        </div>
    </div>
}

export default Footer;