import React, { useState } from "react";
import { Dialog } from "../dialog/Dialog";
import toast from "react-hot-toast";
import SVGS from "../../assets/svg";

interface SubmitButtonDialogIProps {
  show: boolean;
  onHide: () => void;
  submitAction: (data: any) => void;
}

const SubmitButtonDialog = ({
  show,
  onHide,
  submitAction,
}: SubmitButtonDialogIProps) => {
  const [ordinalXverseAddress, setOrdinalXverseAddress] = useState<string>("");
  const [evmAddress, setEvmAddress] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateEvmAddress = (address: string): boolean => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const validateTaprootAddress = (address: string): boolean => {
    return /^(bc1p)[0-9a-z]{42,}$/.test(address);
  };

  const handleSubmitAction = () => {
    if (!ordinalXverseAddress) {
      toast.error("Ordinal Xverse Address Is Required");
      return;
    } else if (!validateTaprootAddress(ordinalXverseAddress)) {
      toast.error("Invalid Taproot Address");
      return;
    } else if (!evmAddress) {
      toast.error("EVM address Is Required");
      return;
    } else if (!validateEvmAddress(evmAddress)) {
      toast.error("Invalid EVM Address");
      return;
    } else if (!emailAddress) {
      toast.error("Email address Is Required");
      return;
    } else if (!validateEmail(emailAddress)) {
      toast.error("Invalid email Address");
      return;
    }
    if (ordinalXverseAddress && evmAddress && emailAddress) {
      submitAction({
        xverseOrdinalAddress: ordinalXverseAddress,
        evmAddress,
        email: emailAddress,
      });
    }
  };
  return (
    <>
      {show && (
        <Dialog
          title={""}
          show={show}
          onHide={onHide}
          showCloseButton={true}
          className="!bg-[#FD591B]"
          size="lg"
        >
          <img src={SVGS.SubmitLogo} className="mx-auto" />
          <div className="w-full text-center text-white text-[22px] font-bold font-['Montserrat'] capitalize">
            Claim All Ordinals Passport <br />
            Airdrops, Raffles, Free Mints
            <br />
            and more
          </div>

          <div className="mt-8">
            <div className="mt-2">
              <div className="ml-1 ">
                <label className="font-['Montserrat'] text-[14px]  font-bold leading-3 text-white">
                  Xverse Ordinals Taproot Address:
                </label>
              </div>
              <div className="mt-2">
                <input
                  value={ordinalXverseAddress}
                  onChange={(e) => {
                    e.preventDefault();
                    setOrdinalXverseAddress(e.target.value);
                  }}
                  type="text"
                  className=" border border-white text-black text-sm rounded-lg block w-full p-2.5 bg-white"
                />
              </div>
            </div>
            <div className="mt-2">
              <div className="ml-1 ">
                <label className="font-['Montserrat'] text-[14px] font-bold leading-3 text-white">
                  EVM Address:
                </label>
              </div>
              <div className="mt-2">
                <input
                  value={evmAddress}
                  onChange={(e) => {
                    e.preventDefault();
                    setEvmAddress(e.target.value);
                  }}
                  type="text"
                  className=" border border-white text-sm rounded-lg block w-full p-2.5 bg-white text-black"
                />
              </div>
            </div>
            <div className="mt-2">
              <div className="ml-1 ">
                <label className="font-['Montserrat'] text-[14px] font-bold leading-3 text-white">
                  Email Address:
                </label>
              </div>
              <div className="mt-2">
                <input
                  value={emailAddress}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmailAddress(e.target.value);
                  }}
                  type="text"
                  className=" border border-white text-sm rounded-lg block w-full p-2.5 bg-white text-black "
                />
              </div>
            </div>

            <div
              className="rounded-xl bg-white mt-8 cursor-pointer"
              onClick={() => {
                handleSubmitAction();
              }}
            >
              <div className="flex justify-center items-center p-4 cursor-pointer bg-black text-white rounded-xl uppercase">
                <img src={SVGS.SubmitIcon} alt="submit icon" className="" />
                <label className="ml-2 font-montserrat  font-bold text-[16px] leading-5 cursor-pointer">
                  Enter Raffle
                </label>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default SubmitButtonDialog;
