import React from "react";
import { Dialog } from "../dialog/Dialog";
import SVGS from "../../assets/svg";
import { copyTextToClipboard } from "../../utils/helpers";
import toast from "react-hot-toast";
interface ConnectDiscordDialogIProps {
  show: boolean;
  onHide: () => void;
  passportId:string;
  vrf:string;
}

const PassportDetailsDialog = ({
  show,
  onHide,
  passportId,
  vrf,
}: ConnectDiscordDialogIProps) => {
  return (
    <>
      {show && (
        <Dialog
          title="PASSPORT ADDRESS"
          show={show}
          onHide={onHide}
          showCloseButton={true}
          size="lg"
        >
          <div className="mt-8">
            <div>
              <div>
                <label className="font-pt-mono text-[14px] font-normal leading-3 text-white">
                  Passport Number:
                </label>
              </div>
              <div>
                <label className="font-monterrat text-[30px] font-bold leading-9 text-white">
                  {passportId}
                </label>
              </div>
            </div>
            <div className="mt-4">
              <div>
                <label className="font-pt-mono text-[14px] font-normal leading-3 text-white">
                  Passport Address:
                </label>
              </div>
              <div className="flex justiy-center">
                <label className="font-monterrat text-[30px] font-bold leading-9 text-white">
                  {vrf ? `${vrf.substring(0,15)}...` : ""}
                </label>
                <img
                  className="ml-4 cursor-pointer"
                  src={SVGS.CopyIcon}
                  alt="copy-icon"
                  onClick={() => {
                    copyTextToClipboard(vrf);
                    toast.success("Copied");
                  }}
                />
              </div>
            </div>
            <div
              className="rounded-xl mt-8 cursor-pointer bg-[#E94000]"
              onClick={() => {
                window.open("https://x.com/intent/post?text=%F0%9F%9A%80+Just%20grabbed%20my%20Rare%20Ordinals%20Passport%21%20Ready%20to%20dive%20into%20%40NFCsummit%20%26%20%40OrdinalsLisbon.%0A%0A%F0%9F%94%A5Get%20hyped%20for%20sun%2C%20sea%2C%20sats%2C%20and%20serious%20degen%20vibes.%0A%0A%F0%9F%91%89Come%20join%20us%0A%0APowered%20by%20%20%40FerrumNetwork%20%40SeizeCTRL%20and%20of%20course%20%40Pizza_Ninjas","_blank")
              }}
            >
              <div className="flex justify-center items-center p-4 cursor-pointer">
                <img
                  className="cursor-pointer"
                  src={SVGS.ShareIcon}
                  alt="discord-logo"
                />
                <label className="ml-2 font-montserrat font-bold text-[16px] leading-5 cursor-pointer text-white">
                  SHARE PASSPORT
                </label>
              </div>
            </div>
            <div className="flex justify-center mt-8">
              <img src={SVGS.ChainLinkLogo} alt="chain-link-logo" />
              <div className="ml-2">
                <span>
                  <label className="font-orca text-[8.3px] font-normal leading-3 text-white small-line-height">
                    {"POWERED BY \n"}
                  </label>
                </span>
                <span>
                  <label className="font-orca text-[9.86px] font-normal leading-3 text-white small-line-height">
                    CHAINLINK VRF
                  </label>
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default PassportDetailsDialog;
