import React from "react";
import { Dialog } from "../dialog/Dialog";
import SVGS from "../../assets/svg";

interface ConnectDiscordDialogIProps {
  show: boolean;
  onHide: () => void;
  connnectDiscord: () => void;
}

const ConnectDiscordDialog = ({ show, onHide, connnectDiscord }: ConnectDiscordDialogIProps) => {
  return (
    <>
      {show && (
        <Dialog
          title="CONNECT TO CLAIM PASSPORT"
          show={show}
          onHide={onHide}
          showCloseButton={true}
          size="lg"
        >
          <div className="mt-8">
            <div>
              <label className="font-pt-mono text-[12px] font-normal leading-3 text-white">
                {
                  "Claim and secure your passport number and privacy with your Discord account. Once you claim your Passport Profile with Discord, you can make updates to your Web3 Name and PFP."
                }
              </label>
            </div>
            <div className="rounded-xl bg-white mt-8 cursor-pointer" onClick={()=>{
                connnectDiscord();
            }}>
              <div className="flex justify-center items-center p-4 cursor-pointer">
                <img className="cursor-pointer" src={SVGS.DiscordLogo} alt="discord-logo" />
                <label className="ml-2 font-montserrat font-bold text-[16px] leading-5 cursor-pointer">Connect Discord</label>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ConnectDiscordDialog;
