//@ts-ignore
import axios from "axios";

//@ts-ignore
import queryString from "query-string";

const CLIENT_ID = "1244035983926886530";
const CLIENT_SECRECT = "pXrXw9O8iDYlX13vGOn6I2CwWz_p0rmt";
const ORDINALS_PASSPORT_CHANNEL_ID = "1243251283339645008";
const REDIRECT_URI = "https://ordinalspassport.com/callback";
// const REDIRECT_URI = "http://localhost:3000/callback";
const AUTHORIZATION_ENDPOINT = "https://discord.com/api/oauth2/authorize";
const TOKEN_ENDPOINT = "https://discord.com/api/oauth2/token";

export const getAuthUrl = () => {
  const params = queryString.stringify({
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    response_type: "code",
    scope: "identify email guilds",
  });

  return `${AUTHORIZATION_ENDPOINT}?${params}`;
};

export const getToken = async (code: string) => {
  try {
    const params = new URLSearchParams();
    params.append("client_id", CLIENT_ID);
    params.append("client_secret", CLIENT_SECRECT);
    params.append("grant_type", "authorization_code");
    params.append("code", code);
    params.append("redirect_uri", REDIRECT_URI);
    // console.log(params.toString());
    const response = await fetch(TOKEN_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching token:", error);
  }
};

export const getUserData = async (accessToken: string) => {
  try {
    const response = await fetch("https://discord.com/api/users/@me", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const user = await response.json();
    return user;
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export const checkUserIfUserIsAlreadyPartOfOrdinalsChannel = async (
  token: string
) => {
  const channels = await axios
    .get(`https://discord.com/api/users/@me/guilds`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  if (channels?.length) {
    const result = channels.find(
      (channel: any) => channel.id === ORDINALS_PASSPORT_CHANNEL_ID
    );
    if (result) {
      return true;
    }
  }

  return false;
};

export const storeToken = (token: string) => {
  localStorage.setItem("discord_access_token", token);
};

export const getStoredToken = () => {
  return localStorage.getItem("discord_access_token");
};

export const removeStoredToken = () => {
  localStorage.removeItem("discord_access_token");
};
